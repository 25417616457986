import { Admin, Resource } from 'react-admin';
import UserIcon from '@mui/icons-material/People';

import { dataProvider } from './dataProvider';
import { UserList } from './components/userList';

const App = () => {
  return (
    <Admin dataProvider={dataProvider}>
      <Resource name="user" icon={UserIcon} list={UserList} />
    </Admin>
  );
};

export default App;
