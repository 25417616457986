import { List, Datagrid, TextField, DeleteButton, DateField, TextInput } from 'react-admin';

const postFilters = [<TextInput label="ID" source="_id" alwaysOn />];

export const UserList = (props: any) => {
  return (
    <List {...props} filters={postFilters}>
      <Datagrid>
        <TextField source="_id" />
        <TextField source="name" />
        <TextField source="email" />
        <DateField source="createdAt" />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};
